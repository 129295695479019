
export function SuperVenue(props) {
    return (
        <svg {...props} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <g clipPath="url(#clip_supervenue_badge_40)">
                <path d="M13.5993 39.0001L11.589 33.1771L6 35.2886L13.8314 18.7114L21.4308 22.4229L13.5993 39.0001Z" fill="currentColor" className="text-primary brightness-75"></path>
                <path d="M26.4007 39.0001L28.4112 33.1771L34 35.2886L26.1688 18.7114L18.5693 22.4229L26.4007 39.0001Z" fill="currentColor" className="text-primary brightness-75"></path>
                <path d="M33.5204 14.1871C33.5204 15.5893 31.8064 16.6991 31.4027 17.9644C30.9839 19.2757 31.7143 21.195 30.9332 22.2863C30.1442 23.3885 28.1181 23.2711 27.034 24.0738C25.9604 24.8681 25.4483 26.868 24.1585 27.2938C22.9142 27.7046 21.3495 26.4062 19.9704 26.4062C18.5912 26.4062 17.0264 27.7046 15.7821 27.2938C14.4924 26.868 13.9803 24.8681 12.9067 24.0738C11.8223 23.2714 9.79625 23.3886 9.00715 22.2861C8.22587 21.1947 8.95642 19.2755 8.53765 17.9642C8.13329 16.6991 6.41943 15.5893 6.41943 14.1871C6.41943 12.7851 8.13329 11.6753 8.53719 10.41C8.9558 9.09871 8.22556 7.17955 9.00669 6.08811C9.79579 4.98593 11.822 5.10297 12.9062 4.30049C13.9796 3.50611 14.4919 1.50653 15.7816 1.08059C17.026 0.66976 18.5908 1.96816 19.9699 1.96816C21.349 1.96816 22.9137 0.66976 24.1582 1.08074C25.4479 1.50653 25.9601 3.50627 27.0335 4.30065C28.1177 5.10297 30.1439 4.98577 30.9327 6.08827C31.7141 7.1797 30.9834 9.09871 31.4022 10.41C31.8066 11.6753 33.5204 12.7849 33.5204 14.1871V14.1871Z" fill="currentColor" className="text-primary brightness-75"></path>
                <path d="M33.5204 14.1871C33.5204 15.5893 31.8064 16.6991 31.4027 17.9644C30.9839 19.2757 31.7143 21.195 30.9332 22.2863C30.1442 23.3885 28.1181 23.2711 27.034 24.0738C25.9604 24.8681 25.4483 26.868 24.1585 27.2938C22.9142 27.7046 21.3495 26.4062 19.9704 26.4062C18.5912 26.4062 17.0264 27.7046 15.7821 27.2938C14.4924 26.868 13.9803 24.8681 12.9067 24.0738C11.8223 23.2714 9.79625 23.3886 9.00715 22.2861C8.22587 21.1947 8.95642 19.2755 8.53765 17.9642C8.13329 16.6991 6.41943 15.5893 6.41943 14.1871C6.41943 12.7851 8.13329 11.6753 8.53719 10.41C8.9558 9.09871 8.22556 7.17955 9.00669 6.08811C9.79579 4.98593 11.822 5.10297 12.9062 4.30049C13.9796 3.50611 14.4919 1.50653 15.7816 1.08059C17.026 0.66976 18.5908 1.96816 19.9699 1.96816C21.349 1.96816 22.9137 0.66976 24.1582 1.08074C25.4479 1.50653 25.9601 3.50627 27.0335 4.30065C28.1177 5.10297 30.1439 4.98577 30.9327 6.08827C31.7141 7.1797 30.9834 9.09871 31.4022 10.41C31.8066 11.6753 33.5204 12.7849 33.5204 14.1871V14.1871Z" fill="currentColor" className="text-primary brightness-75"></path>
                <path d="M19.8345 23.9136C25.3371 23.9136 29.7978 19.3781 29.7978 13.7832C29.7978 8.18836 25.3371 3.65283 19.8345 3.65283C14.3318 3.65283 9.87109 8.18836 9.87109 13.7832C9.87109 19.3781 14.3318 23.9136 19.8345 23.9136Z" fill="currentColor" className="text-primary brightness-125"></path>
                <path d="M19.3011 7.50985C19.6743 6.74118 20.2847 6.74118 20.6579 7.50985L21.5274 9.3009C21.9006 10.0696 22.8884 10.7993 23.7225 10.9225L25.6666 11.2098C26.5008 11.3331 26.6896 11.9234 26.0857 12.5217L24.6792 13.9158C24.0755 14.5142 23.6983 15.695 23.8405 16.5398L24.1726 18.5083C24.3151 19.3532 23.8211 19.7181 23.0749 19.3192L21.3361 18.3897C20.5899 17.9907 19.3688 17.9907 18.6226 18.3897L16.8837 19.3192C16.1375 19.7182 15.6436 19.3532 15.786 18.5083L16.1182 16.5398C16.2607 15.6949 15.8833 14.5142 15.2796 13.9158L13.8726 12.5216C13.2689 11.9233 13.4575 11.3329 14.2918 11.2096L16.236 10.9224C17.0704 10.7991 18.0584 10.0692 18.4313 9.30074L19.3011 7.50985V7.50985Z" fill="white"></path>
            </g>
            <defs>
                <clipPath id="clip_supervenue_badge_40">
                    <rect x="6" y="1" width="28" height="38" fill="white"></rect>
                </clipPath>
            </defs>
        </svg>
    );
}
