import { ChevronRightIcon } from "@heroicons/react/24/solid";
import { IconButton } from "@material-tailwind/react";

export default function CustomRightArrow({ onClick, ...rest }) {
    const { visible } = { ...rest };
    const {
        onMove,
        carouselState: { currentSlide, deviceType }
    } = rest;

    const handleOnclick = (e) => {
        e.preventDefault();

        onClick(e);
    }
    // onMove means if dragging or swiping in progress.
    return <IconButton
        key={"space_right_arrow"}
        className={`!right-2 !top-[50%] !px-[5px] !py-0 !translate-y-[-10px] !absolute  rounded-full bg-base-100 w-7 h-7 ${!visible ? "invisible group-hover:visible" : ""}`}
        size="sm"

        onClick={handleOnclick}>
        <ChevronRightIcon
            strokeWidth={2}
            className="h-5 w-5 text-base-content"
        />
    </IconButton>;
};