import React from 'react';
// import stylesheet if you're not already using CSS @import
import 'react-image-gallery/styles/css/image-gallery.css';

export function BaseVerticalCard(props) {
  const { onClick } = props;
  const classNameProp = props.className ?? "";
  return (
    <div className={`card card-compact w-auto bg-base-100 shadow-xl  ${classNameProp}`} >
      {props.children}
    </div>
  );
}
