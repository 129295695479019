export function Person(props) {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg">
      <circle cx="8.5" cy="2.5" r="1.5" fill="currentColor"></circle>
      <rect
        x="7"
        y="5"
        width="3"
        height="10"
        rx="0.5"
        fill="currentColor"></rect>
      <path
        d="M6 6C6 5.44772 6.44772 5 7 5H10C10.5523 5 11 5.44772 11 6V9.5C11 9.77614 10.7761 10 10.5 10H6.5C6.22386 10 6 9.77614 6 9.5V6Z"
        fill="currentColor"></path>
    </svg>
  );
}
