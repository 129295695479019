/* eslint-disable @next/next/no-img-element */
"use client"
import {
  MapPinIcon,
  // HeartIcon,
  StarIcon,
} from '@heroicons/react/24/solid';
import { HeartIcon } from '@heroicons/react/24/outline';
// import { Chip, IconButton } from '@material-tailwind/react';
import React, { useEffect, useRef, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
// import stylesheet if you're not already using CSS @import

import Link from 'next/link';
import { BaseVerticalCard } from './BaseVerticalCard';
import { Chair } from '../svg/Chair';
import { Person } from '../svg/Person';
import isEmpty from 'utilities/isEmpty';
import Image from 'next/image';
import { Chip, IconButton } from '@material-tailwind/react';
import { SuperVenue } from '../svg/SuperVenue';
import CustomLeftArrow from '../carousel/CustomLeftArrow';
import CustomRightArrow from '../carousel/CustomRightArrow';
import localiseCurrency from 'utilities/localiseCurrency';
import { RatePeriods, RateTypes } from 'utilities/constants';

//This is a fix for janky animations in scroll with react multi carousel
//see https://github.com/akiran/react-slick/issues/1240
let firstClientX, clientX;
const preventTouch = e => {
  const minValue = 5; // threshold

  clientX = e.touches[0].clientX - firstClientX;

  // Vertical scrolling does not work when you start swiping horizontally.
  if (Math.abs(clientX) > minValue) {
    e.preventDefault();
    e.returnValue = false;

    return false;
  }
};
const touchStart = e => {
  firstClientX = e.touches[0].clientX;
};

export function SpaceCard(props) {
  const { disable, data } = props;
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  const numberOfImagesToPreload = 3;

  let containerRef = useRef();

  useEffect(() => {
    //We do this to prevent client errors
    setIsTouchDevice(getIsTouchDevice());

    if (containerRef.current) {
      containerRef.current.addEventListener("touchstart", touchStart);
      containerRef.current.addEventListener("touchmove", preventTouch, {
        passive: false
      });
    }

    return () => {
      if (containerRef.current) {
        containerRef.current.removeEventListener("touchstart", touchStart);
        containerRef.current.removeEventListener("touchmove", preventTouch, {
          passive: false
        });
      }
    };



  }, []);

  const getIsTouchDevice = () => {
    return (typeof window != "undefined" && (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0)));
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const getPeriodText = (ratePeriodId) => {
    if (ratePeriodId != null) {
      switch (ratePeriodId) {
        case RatePeriods.PER_HOUR:
          return "per hour";
        case RatePeriods.PER_SESSION:
          return "per session";
        case RatePeriods.PER_MORNING:
          return "per morning";
        case RatePeriods.PER_AFTERNOON:
          return "per afternoon";
        case RatePeriods.PER_EVENING:
          return "per evening";
        case RatePeriods.PER_DAY:
          return "per day";
      }
    }

    return null;
  }

  const getRateTypeText = (rateTypeId, ratePeriodId) => {
    if (rateTypeId != null) {
      switch (rateTypeId) {
        case RateTypes.HIREFEE:
          return "hire fee";
        case RateTypes.MINIMUM_SPEND:
          return "min. spend";
        case RateTypes.PACKAGE_PER_PERSON:
          return "per person";
        case RateTypes.HIRE_FEE_AND_MINIMUM_SPEND:
          return "hire fee + min. spend";
        case RateTypes.HIRE_FEE_AND_PACKAGE_PER_PERSON:
          return "hire fee + per person";
      }
    }

    return null;
  }

  return (
    <Link
      href={data.url}
      className="no-underline group" suppressHydrationWarning={true}>
      <BaseVerticalCard>
        <figure className={'not-prose bg-gray-300'} ref={containerRef}>
          {!disable && !isEmpty(data.photos) && data.photos.length > 0 && <Carousel
            afterChange={(previousSlide, { currentSlide, onMove }) => {
              setCurrentSlideIndex(currentSlide);
            }}
            swipeable={isTouchDevice}
            draggable={false}
            responsive={responsive}
            ssr
            infinite
            containerClass="space-carousel"
            itemClass="space-carousel-item"
            deviceType={"desktop"}
            arrows={!isTouchDevice}
            // customTransition="transform 500ms linear"
            // transitionDuration={500}
            partialVisible={false}
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {data.photos.map((photo, index) => <div key={`space_image_${index}`}>
              {(index - numberOfImagesToPreload) <= currentSlideIndex && <img loading="lazy" draggable="false" className='w-full' width="500px" height={"350px"} src={`${photo.url}?width=500&height=350&rmode=crop&scale=both&quality=100`} alt='AWE' />}
            </div>)}
          </Carousel>}
          {disable && <div className='space-carousel-item'>
            {!isEmpty(data.photos) && data.photos.length > 0 && <div><img draggable="false" className='w-full' src={`${data.photos[0].url}?width=500&height=350&rmode=crop&scale=both&quality=100`} alt='AWE' /></div>}
          </div>}
          <IconButton
            className={`!right-2 !top-[20px] !px-[5px] !py-0 !translate-y-[-10px] !absolute  rounded-full bg-base-100 w-7 h-7`}
            size="sm"
          >
            <HeartIcon
              strokeWidth={2}
              className="h-5 w-5 text-base-content"
            />
          </IconButton>
          {!isEmpty(data.minimumAmount) && <div
            data-theme="light"
            className={`!right-2 !bottom-[76px] !py-0 !pb-1 !px-2  !absolute bg-black/80 text-white  text-center text-xs rounded-md  bg-opacity-90 leading-1 font-light`}>
            <div>
              from <span className="text-lg font-bold" suppressHydrationWarning={true}>{localiseCurrency(data.minimumAmount, 0)}</span>
            </div>
            {!isEmpty(data.minimumRatePeriodId) && !isEmpty(data.minimumRateTypeId) && <div className='text-xs'>{getRateTypeText(data.minimumRateTypeId)} / {getPeriodText(data.minimumRatePeriodId)}</div>}
          </div>}

        </figure>

        <div className="card-body prose !leading-[0.2rem] !gap-0 not-prose !pl-2 !pr-2 !pt-1 !pb-2">
          <div className="!mt-0 !mb-0 text-base-content text-sm  flex justify-between">
            <div className="truncate text-ellipsis font-semibold">
              {data?.spaceName}
            </div>
            <div className="flex flex-shrink-0 pl-1">
              {!isEmpty(data?.maxSeated) &&
                <>
                  <Chair className="h-[14px] w-[14px] mt-[3px] text-base-content" />
                  {data?.maxSeated}
                </>}
              {!isEmpty(data?.maxStanding) &&
                <>
                  <Person className=" h-[14px] w-[14px] mt-[3px] text-base-content" />
                  {data?.maxStanding}
                </>}
            </div>
          </div>
          <div className="text-sm flex justify-between">
            <div className="truncate text-ellipsis">
              {data?.venueName}
            </div>
            {!isEmpty(data?.averageMeanRating) && data?.averageMeanRating > 0 &&
              <div className="flex flex-shrink-0 pl-1 ">
                <StarIcon
                  strokeWidth={2}
                  className="h-[14px] w-[14px] mt-[3px] text-primary"
                />
                {data?.averageMeanRating.toFixed(1)}
              </div>}
          </div>
          <div className="text-sm flex justify-between">
            <div className="truncate text-ellipsis">
              <div className="text-xs  flex row-auto items-center">
                <div>
                  <MapPinIcon strokeWidth={0} className="h-4 w-4 text-primary" />
                </div>
                <div>&nbsp;{data?.address}</div>
              </div>
            </div>
            <div className="flex flex-shrink-0 pl-1 text-xs">
              <SuperVenue
                strokeWidth={2}
                className="h-4 w-4   text-primary"
              />
              #Supervenue
            </div>
          </div>

        </div>
      </BaseVerticalCard>
    </Link>
  );
}
